import { node } from "../../Axios";
import {
  getSession,
  removeSession,
  setAttesaNuovo,
  setCarrello,
  setSession,
} from "../../utils/session";
import {
  recupera_carrello,
  set_carrello_tmp_online,
  set_coperti,
  set_formula,
  set_id_ordine,
  set_pin_tavolo,
  set_ruolo_apertura_tavolo,
  set_storico_ordine_aperto,
  set_storico_uscite_ordine,
  set_tavolo,
  set_tipo,
  set_totale_generale,
} from "./ordini";
import { setErrore } from "./useform";
import mqtt from "mqtt-browser";
import mqttTopics from "../../utils/topics";

export const DESTROY_SESSION = "DESTROY_SESSION";

export const IDRUOLO = "IDRUOLO";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GETMENU_START = "GETMENU_START";
export const GETMENU_SUCCESS = "GETMENU_SUCCESS";
export const GETMENU_FAIL = "GETMENU_FAIL";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const ATTESANUOVO = "ATTESANUOVO";
export const GET_CATEGORIE_START = "GET_CATEGORIE_START";
export const GET_CATEGORIE_SUCCESS = "GET_CATEGORIE_SUCCESS";
export const GET_CATEGORIE_FAIL = "GET_CATEGORIE_FAIL";
export const LOADING = "LOADING";
export const MQTT_CLIENT_DISCONNECTED = "MQTT_CLIENT_DISCONNECTED";
export const MQTT_CLIENT_CONNECTED = "MQTT_CLIENT_CONNECTED";
export const MQTT_CONNECTION_ERROR = "MQTT_CONNECTION_ERROR";

export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";

export const SET_MQTT_OPTIONS = "SET_MQTT_OPTIONS";
export const SET_PIN = "SET_PIN";
export const SET_PIN_FAIL = "SET_PIN_FAIL";

export const login_start = () => {
  return {
    type: LOGIN_START,
  };
};
export const login_success = (res) => {
  return {
    type: LOGIN_SUCCESS,
    res,
  };
};
export const login_fail = (value) => {
  return {
    type: LOGIN_FAIL,
    value,
  };
};

export const getmenu_start = () => {
  return {
    type: GETMENU_START,
  };
};
export const getmenu_success = (res) => {
  return {
    type: GETMENU_SUCCESS,
    res,
  };
};
export const getmenu_fail = () => {
  return {
    type: GETMENU_FAIL,
  };
};

export const salvaRuoloScelto = (id_ruolo) => {
  return {
    type: IDRUOLO,
    id_ruolo,
  };
};

export const login = (key, tipo) => {
  return async (dispatch) => {
    await dispatch(get_categorie_start());
    await dispatch(loading_login(true));

    try {
      const config = {};

      const bodyParameters = {
        key: key,
        tipo,
      };
      const res = await node.post(
        "/api/login/loginspecial",
        bodyParameters,
        config
      );
      console.log("LOGIN", res);
      if (res.data.messaggio == "tavolo") {
        await dispatch(login_success(res.data.ordine.keyorder));
        await dispatch(set_formula(res.data.ordine.id_formula));
        await dispatch(set_id_ordine(res.data.ordine.ordiniIdOrdine));
        await dispatch(get_categorie(res.data.ordine.keyorder, tipo));
        await dispatch(set_tipo(res.data.ordine.ordini.tipo));
        await dispatch(
          salvaRuoloScelto(
            res.data.ordine.ordini.persone.ruolis[0].id_ruolo
          )
        );
        await dispatch(
          set_storico_uscite_ordine(res.data.ordine.ordini.storico_uscite)
        );

        await dispatch(set_pin_tavolo(res.data.ordine.pin_ordine));
        await dispatch(
       set_ruolo_apertura_tavolo(res.data.ordine.ordini.persone.ruolis[0].id_ruolo)
        );
        if (res.data.ordine.ordini.persone.ruolis[0].id_ruolo == 3) {
          await dispatch(set_totale_generale(res.data.ordine.ordini.totale));
          await dispatch(
            set_storico_ordine_aperto(res.data.ordine.ordini.storico)
          );
          await dispatch(set_carrello_tmp_online(res.data.car_tmp_online));
          await dispatch(
            set_storico_uscite_ordine(res.data.ordine.ordini.storico_uscite)
          );
        }
      }
      if (res.data.messaggio == "ombrellone") {
        await dispatch(login_success(res.data.ordine.keyorder));
        await dispatch(
          set_totale_generale(
            res.data.ordine.ordini_ombrellonis[0].ordini.totale
          )
        );
        await dispatch(
          set_formula(res.data.ordine.ordini_ombrellonis[0].formuleIdFormula)
        );
        await dispatch(
          set_id_ordine(res.data.ordine.ordini_ombrellonis[0].ordini.id_ordine)
        );
        await dispatch(get_categorie(res.data.ordine.keyorder, tipo));
        await dispatch(
          set_tipo(res.data.ordine.ordini_ombrellonis[0].ordini.tipo)
        );

        await dispatch(
          set_storico_ordine_aperto(
            res.data.ordine.ordini_ombrellonis[0].ordini.storico
          )
        );
        await dispatch(
          set_storico_uscite_ordine(
            res.data.ordine.ordini_ombrellonis[0].ordini.storico_uscite
          )
        );
        await dispatch(
          set_ruolo_apertura_tavolo(res.data.ordine.ordini.persone.ruolis[0].id_ruolo)
           );
      }
      const mqttOptions = {
        broker: res.data.azienda.broker_url,
        username: res.data.azienda.usermqtt,
        password: res.data.azienda.passmqtt,
        port: res.data.azienda.portws,
        protocol: "wss",
      };
      const topics = mqttTopics.map(
        (topic) => `${res.data.azienda.basetopic}${topic}`
      );
      if (!mqttOptions) {
        // Gestisci il caso in cui mqttOptions sia nullo
        console.error("mqttOptions is null");
        return;
      }

      await dispatch({ type: FETCH_TOPICS_SUCCESS, payload: topics });
      await dispatch({
        type: SET_MQTT_OPTIONS,
        payload: mqttOptions,
      });
      await dispatch(loading_login(false));
    } catch (error) {
      console.log(error);
      if (error.response.status == "404") {
        await dispatch(login_fail());
      }
    }
  };
};

export const verifica_pin = (key, pinLocal) => {
  return async (dispatch, getState) => {
    // await dispatch(loading_login(true));

    try {
      const config = {};

      const bodyParameters = {
        key: key,
        pin: pinLocal,
        tipo: getState().ordini.tipo,
      };
      const res = await node.post(
        "/api/login/verificapin",
        bodyParameters,
        config
      );
      console.log("PIN", res);
      if (res.data.messaggio == "ok") {
        await dispatch(set_pin(res.data.pin));
        return true;
      } else {
        await dispatch(set_pin_fail(res.data.messaggio));
        return false;
      }

      // await dispatch(loading_login(false));
    } catch (error) {
      // await dispatch(loading_login(false));
    }
  };
};
export const get_categorie = (key, tipo) => {
  return async (dispatch, getState) => {
    await dispatch(get_categorie_start());
    try {
      const config = {};

      const bodyParameters = {
        key: key,
        tipo: tipo,
      };
      const res = await node.post(
        "/api/categorie/getcategorieqr",
        bodyParameters,
        config
      );
      console.log("CATEGORIE", res);

      await dispatch(get_categorie_success(res));
    } catch (error) {
      await dispatch(get_categorie_fail());
    }
  };
};
export const get_categorie_start = () => {
  return {
    type: GET_CATEGORIE_START,
  };
};
export const get_categorie_success = (res) => {
  return {
    type: GET_CATEGORIE_SUCCESS,
    res,
  };
};
export const get_categorie_fail = () => {
  return {
    type: GET_CATEGORIE_FAIL,
  };
};
export const loading_login = (value) => {
  return {
    type: LOADING,
    value,
  };
};
export const set_pin = (value) => {
  return {
    type: SET_PIN,
    value,
  };
};
export const set_pin_fail = (value) => {
  return {
    type: SET_PIN_FAIL,
    value,
  };
};
export const onClear = () => {
  return { type: DESTROY_SESSION };
};

export const connectMqtt = () => {
  return async (dispatch, getState) => {
    try {
      const mqttOptions = getState().login.mqttOptions;
      if (!mqttOptions) {
        // Gestisci il caso in cui mqttOptions sia nullo
        console.error("mqttOptions is null");
        return;
      }
      console.log("MQTT PRTION", mqttOptions);
      const client = mqtt.connect(mqttOptions.broker, {
        username: mqttOptions.username,
        password: mqttOptions.password,
        port: parseInt(mqttOptions.port),
        protocol: "wss",
      });

      client.on("connect", () => {
        console.log("Connesso al broker MQTT");
        // Puoi eseguire le operazioni desiderate qui, sicuro che il client sia connesso
      });

      client.on("error", async (error) => {
        console.error("Errore durante la connessione MQTT:", error);
      });
      await dispatch({ type: MQTT_CLIENT_CONNECTED, payload: client });
      if (getState().login.topics && Array.isArray(getState().login.topics)) {
        getState().login.topics.forEach((topic) => {
          client.subscribe(topic, (err) => {
            if (err) {
              console.error("MQTT subscribe error:", err);
            } else {
              console.log("Subscribed to topic:", topic);
            }
          });
        });
      }
      client.on("message", (topic, message) => {
        if (topic === getState().login.topics[0]) {
          const obj = JSON.parse(message.toString());
          if (getState().ordini.id_ordine == obj.id_ordine) {
            dispatch(set_storico_uscite_ordine(obj.storico_uscite));
            // dispatch(get_ordini_aperti_sala());
          }
        }
        if (topic === getState().login.topics[1]) {
          const obj = JSON.parse(message.toString());
          if (getState().ordini.id_ordine == obj.id_ordine) {
            dispatch(set_carrello_tmp_online(obj.car_tmp_online));
            // dispatch(get_ordini_aperti_sala());
          }
        }
        if (topic === getState().login.topics[2]) {
          const obj = JSON.parse(message.toString());
          if (getState().ordini.id_ordine == obj.id_ordine) {
            dispatch(set_totale_generale(parseFloat(obj.totale)));

            dispatch(set_storico_ordine_aperto(obj.storico));
            dispatch(set_storico_uscite_ordine(obj.storico_uscite));
            // dispatch(get_ordini_aperti_sala());
          }
        }
      });
    } catch (error) {
      console.log("Errore durante la connessione MQTT:", error);
      await dispatch({ type: MQTT_CONNECTION_ERROR, payload: error.message });
    }
  };
};
