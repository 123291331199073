import { isContentEditable } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import "../style/BaseInfo.css";
import RadioButton from "./RadioButton";

const BunsInfo = (props) => {
  let theme = props.formData.base_pane;
  console.log(theme);

  const onChangeTheme = (e) => {
    const { id } = e.target;
    const pos = props.basePane.findIndex((p) => p.id_ingredienti == id);

    theme.forEach((element, index) => {
      if (pos === index) {
        theme[index] = true;
      } else {
        theme[index] = false;
      }
    });

    props.setFormData({
      ...props.formData,
      totale:
        Number(props.formData.totale) + Number(props.basePane[pos].prezzo),
      base_pane: theme,
      pane: e.target.value,
    });
  };

  return (
    <div className="baseinfo-container">
      <div className="nome-container">
        <div className="spazio_input">
          <input
            id="first-name"
            type="text"
            className="campo_input"
            placeholder="Scrivi il tuo nome"
            required
            onChange={(e) =>
              props.setFormData({ ...props.formData, nome: e.target.value })
            }
            value={props.formData.nome}
          />
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Nome</span>
          </span>
        </div>
      </div>
      {props.basePane.map((item, index) => {
        console.log("index pane", index);
        console.log("index Pane booleano", theme[index]);

        return (
          <RadioButton
            key={index}
            id={item.id_ingredienti}
            value={item.nome}
            prezzo={item.prezzo}
            text={item.nome}
            onChange={onChangeTheme}
            checked={theme[index]}
          />
        );
      })}
    </div>
  );
};

export default BunsInfo;
