import { node } from "../../Axios";
import { removecarrello } from "../../utils/session";
import { onClear } from "./login";

export const CARRELLO_START = "CARRELLO_START";
export const CARRELLO_SUCCESS = "CARRELLO_SUCCESS";
export const CARRELLO_FAIL = "CARRELLO_FAIL";

/* export const INSERT_CAR_START = "INSERT_CAR_START";
export const NSERT_CAR_SUCCESS = "NSERT_CAR_SUCCESS";
export const NSERT_CAR_FAIL = "NSERT_CAR_FAIL"; */
export const INCREMENTA_PRODOTTO = "INCREMENTA_PRODOTTO";
export const AGGIUNGI_PRODOTTO_CART = "AGGIUNGI_PRODOTTO_CART";
export const DECREMENTA_PRODOTTO = "DECREMENTA_PRODOTTO";
export const RECUPERA_CARRELLO = "RECUPERA_CARRELLO";

export const INIZIALIZE = "INIZIALIZE";
export const SET_COPERTI = "SET_COPERTI";

export const GET_PRODOTTI_START = "GET_PRODOTTI_START";
export const GET_PRODOTTI_SUCCESS = "GET_PRODOTTI_SUCCESS";
export const GET_PRODOTTI_FAIL = "GET_PRODOTTI_FAIL";

export const GET_INGREDIENTI_START = "GET_INGREDIENTI_START";
export const GET_INGREDIENTI_SUCCESS = "GET_INGREDIENTI_SUCCESS";
export const GET_INGREDIENTI_FAIL = "GET_INGREDIENTI_FAIL";

export const GET_OFFERTE_START = "GET_OFFERTE_START";
export const GET_OFFERTE_SUCCESS = "GET_OFFERTE_SUCCESS";
export const GET_OFFERTE_FAIL = "GET_OFFERTE_FAIL";

export const SEND_ORDINE_START = "SEND_ORDINE_START";
export const SEND_ORDINE_SUCCESS = "SEND_ORDINE_SUCCESS";
export const SEND_ORDINE_FAIL = "SEND_ORDINE_FAIL";

export const SET_TOTALE_GENERALE = "SET_TOTALE_GENERALE";
export const ISCARRELLO = "ISCARRELLO";
export const SET_STORICO = "SET_STORICO";

export const GET_STORICO_ORDINi_START = "GET_STORICO_ORDINi_START";
export const GET_STORICO_ORDINi_SUCCESS = "GET_STORICO_ORDINi_SUCCESS";
export const GET_STORICO_ORDINi_FAIL = "GET_STORICO_ORDINi_FAIL";

export const SET_TAVOLO = "SET_TAVOLO";
export const SET_TIPO = "SET_TIPO";
export const SET_MENU = "SET_MENU";
export const SET_IDORDINE = "SET_IDORDINE";
export const SET_IDFORMULA = "SET_IDFORMULA";

export const MQTT_CLIENT_DISCONNECTED = "MQTT_CLIENT_DISCONNECTED";
export const MQTT_CLIENT_CONNECTED = "MQTT_CLIENT_CONNECTED";
export const MQTT_CONNECTION_ERROR = "MQTT_CONNECTION_ERROR";

export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";

export const SET_STORICO_USCITE_ORDINE = "SET_STORICO_USCITE_ORDINE";
export const SET_RUOLO_APERTURA_TAVOLO = "SET_RUOLO_APERTURA_TAVOLO";
export const SET_CARRELLO_TMP_ONLINE = "SET_CARRELLO_TMP_ONLINE";
export const SET_PIN_TAVOLO = "SET_PIN_TAVOLO";



export const send_ordine = (carrello, totale, token, tipo) => {
  return async (dispatch,getState) => {
    await dispatch(send_ordine_start());

    try {
      const config = {};
      const bodyParameters = {
        carrello: carrello,
        totale: totale.toFixed(2),
        key: token,
        tipo: tipo,
        ruoloaperturatavolo:getState().ordini.ruoloaperturatavolo,

      };
      const res = await node.post(
        "/api/ordini/inviaordineqr",
        bodyParameters,
        config
      );
      await removecarrello();
      console.log(res);

      if (res.data.messaggio == "ombrellone") {
        await dispatch(
          set_totale_generale(
            res.data.ordini.ordini_ombrellonis[0].ordini.totale
          )
        );
        await dispatch(
          set_formula(res.data.ordini.ordini_ombrellonis[0].formuleIdFormula)
        );
        await dispatch(
          set_id_ordine(res.data.ordini.ordini_ombrellonis[0].ordini.id_ordine)
        );
        await dispatch(
          set_tipo(res.data.ordini.ordini_ombrellonis[0].ordini.tipo)
        );
        await dispatch(
          set_totale_generale(
            res.data.ordini.ordini_ombrellonis[0].ordini.totale
          )
        );
        await dispatch(
          set_storico_ordine_aperto(
            res.data.ordini.ordini_ombrellonis[0].ordini.storico
          )
        );
        await dispatch(
          set_storico_uscite_ordine(
            res.data.ordini.ordini_ombrellonis[0].ordini.storico_uscite
          )
        );
      }

      await dispatch(send_ordine_success(res));

      /*  await dispatch(set_totale_generale(res.data.oldtotale+totale));
      
      await dispatch(send_ordine_success(res));
      await dispatch(
        set_storico_ordine_aperto(1)
      ); */
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
    }
  };
};
export const send_ordine_produzione = (carrello, totale, token, tipo) => {
  return async (dispatch) => {
    await dispatch(send_ordine_start());

    try {
      const config = {};
      const bodyParameters = {
        key: token,
        tipo: tipo,
      };
      const res = await node.post(
        "/api/ordini/inviaordineproduzioneqr",
        bodyParameters,
        config
      );
      await removecarrello();
      console.log(res);

      if (res.data.messaggio == "tavolo_qr") {
        await dispatch(
          set_totale_generale(
            res.data.ordini.ordini_ombrellonis[0].ordini.totale
          )
        );
        await dispatch(
          set_formula(res.data.ordini.ordini_ombrellonis[0].formuleIdFormula)
        );
        await dispatch(
          set_id_ordine(res.data.ordini.ordini_ombrellonis[0].ordini.id_ordine)
        );
        await dispatch(
          set_tipo(res.data.ordini.ordini_ombrellonis[0].ordini.tipo)
        );
        await dispatch(
          set_totale_generale(
            res.data.ordini.ordini_ombrellonis[0].ordini.totale
          )
        );
        await dispatch(
          set_storico_ordine_aperto(
            res.data.ordini.ordini_ombrellonis[0].ordini.storico
          )
        );
        await dispatch(
          set_storico_uscite_ordine(
            res.data.ordini.ordini_ombrellonis[0].ordini.storico_uscite
          )
        );
      }

      await dispatch(send_ordine_success(res));

      /*  await dispatch(set_totale_generale(res.data.oldtotale+totale));
      
      await dispatch(send_ordine_success(res));
      await dispatch(
        set_storico_ordine_aperto(1)
      ); */
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
    }
  };
};
export const send_ordine_start = () => {
  return {
    type: SEND_ORDINE_START,
  };
};
export const send_ordine_success = (res) => {
  return {
    type: SEND_ORDINE_SUCCESS,
    res,
  };
};
export const send_ordine_fail = () => {
  return {
    type: SEND_ORDINE_FAIL,
  };
};

export const getcarrello = () => {
  return async (dispatch) => {
    await dispatch(getcarrello_start());

    try {
      const config = {};
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getcarrello",
        bodyParameters,
        config
      );

      await dispatch(getcarrello_success(res));
    } catch (error) {
      await dispatch(get_carrello_fail());
    }
  };
};
export const getcarrello_start = () => {
  return {
    type: CARRELLO_START,
  };
};
export const getcarrello_success = (res) => {
  return {
    type: CARRELLO_SUCCESS,
    res,
  };
};
export const get_carrello_fail = () => {
  return {
    type: CARRELLO_FAIL,
  };
};

export const get_prodotti = (id, token, id_ordine) => {
  return async (dispatch, getState) => {
    await dispatch(get_prodotti_start());

    try {
      const config = {};
      const bodyParameters = {
        id_categoria: id,
        key: token,
        id_ordine: id_ordine,
        tipo: getState().ordini.tipo,
      };
      const res = await node.post(
        "/api/prodotti/getprodottibycategoriaqr",
        bodyParameters,
        config
      );
      console.log(res);
      await dispatch(get_prodotti_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
    }
  };
};
export const get_prodotti_start = () => {
  return {
    type: GET_PRODOTTI_START,
  };
};
export const get_prodotti_success = (res) => {
  return {
    type: GET_PRODOTTI_SUCCESS,
    res,
  };
};
export const get_prodotti_fail = () => {
  return {
    type: GET_PRODOTTI_FAIL,
  };
};

export const get_offerte = (token, id_ordine) => {
  return async (dispatch, getState) => {
    await dispatch(get_offerte_start());

    try {
      const config = {};
      const bodyParameters = {
        key: token,
        id_ordine: id_ordine,
        tipo: getState().ordini.tipo,
      };
      const res = await node.post(
        "/api/prodotti/getofferteqr",
        bodyParameters,
        config
      );

      await dispatch(get_offerte_success(res));
    } catch (error) {
      await dispatch(get_offerte_fail());
    }
  };
};
export const get_offerte_start = () => {
  return {
    type: GET_OFFERTE_START,
  };
};
export const get_offerte_success = (res) => {
  return {
    type: GET_OFFERTE_SUCCESS,
    res,
  };
};
export const get_offerte_fail = () => {
  return {
    type: GET_OFFERTE_FAIL,
  };
};

export const get_storico_ordini = () => {
  return async (dispatch, getState) => {
    await dispatch(get_storico_ordini_start());

    try {
      const config = {};
      const bodyParameters = {
        key: getState().login.token,
        tipo: getState().ordini.tipo,
      };
      const res = await node.post(
        "/api/ordini/getstoricoordineqr",
        bodyParameters,
        config
      );
      console.log(res);
      await dispatch(get_storico_ordini_success(res));
    } catch (error) {
      console.log(error)
      await dispatch(get_prodotti_fail());
    }
  };
};

export const get_storico_ordini_start = () => {
  return {
    type: GET_STORICO_ORDINi_START,
  };
};
export const get_storico_ordini_success = (res) => {
  return {
    type: GET_STORICO_ORDINi_SUCCESS,
    res,
  };
};
export const get_storico_ordini_fail = () => {
  return {
    type: GET_STORICO_ORDINi_FAIL,
  };
};

export const get_ingredienti_antipasto_personalizzata = (token) => {
  return async (dispatch, getState) => {
    await dispatch(get_ingredienti_start());

    try {
      const config = {};
      const bodyParameters = { key: token, tipo: getState().ordini.tipo };
      const res = await node.post(
        "/api/ingredienti/getingredientiperantipastopersonalizzataqr",
        bodyParameters,
        config
      );
      // console.log(res)
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_pizza_personalizzata = (token) => {
  return async (dispatch, getState) => {
    await dispatch(get_ingredienti_start());

    try {
      const config = {};
      const bodyParameters = { key: token, tipo: getState().ordini.tipo };
      const res = await node.post(
        "/api/ingredienti/getingredientiperpezzapersonalizzataqr",
        bodyParameters,
        config
      );
      // console.log(res)
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      console.log(error);
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_piadina_personalizzata = (token) => {
  return async (dispatch, getState) => {
    await dispatch(get_ingredienti_start());

    try {
      const config = {};
      const bodyParameters = { key: token, tipo: getState().ordini.tipo };
      const res = await node.post(
        "/api/ingredienti/getingredientiperpiadinapersonalizzataqr",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_hamburger_personalizzata = (token) => {
  return async (dispatch, getState) => {
    await dispatch(get_ingredienti_start());

    try {
      const config = {};
      const bodyParameters = { key: token, tipo: getState().ordini.tipo };
      const res = await node.post(
        "/api/ingredienti/getingredientiperhamburgerpersonalizzataqr",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_nomi_pizza = (token) => {
  return async (dispatch,getState) => {
    await dispatch(get_ingredienti_start());

    try {
      const config = {};
      const bodyParameters = { key: token ,tipo:getState().ordini.tipo};
      const res = await node.post(
        "/api/prodotti/getnomipizzaqr",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      console.log(error)
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_start = () => {
  return {
    type: GET_INGREDIENTI_START,
  };
};
export const get_ingredienti_success = (res) => {
  return {
    type: GET_INGREDIENTI_SUCCESS,
    res,
  };
};
export const get_ingredienti_fail = () => {
  return {
    type: GET_INGREDIENTI_FAIL,
  };
};
export const inizialize = () => {
  return {
    type: INIZIALIZE,
  };
};
export const incrementa_prodotto = (id_prodotto_interno) => {
  return {
    type: INCREMENTA_PRODOTTO,
    id_prodotto_interno,
  };
};

export const decrementa_prodotto = (id_prodotto_interno) => {
  return {
    type: DECREMENTA_PRODOTTO,
    id_prodotto_interno,
  };
};
export const aggiungi_prodotto_cart = (card) => {
  return {
    type: AGGIUNGI_PRODOTTO_CART,
    card,
  };
};

export const recupera_carrello = (carrello, qty, totale) => {
  return {
    type: RECUPERA_CARRELLO,
    carrello,
    qty,
    totale,
  };
};
export const set_coperti = (coperti) => {
  return {
    type: SET_COPERTI,
    coperti,
  };
};
export const set_totale_generale = (tot_generale) => {
  return {
    type: SET_TOTALE_GENERALE,
    tot_generale,
  };
};
export const set_iscarrello = (value) => {
  return {
    type: ISCARRELLO,
    value,
  };
};
export const set_storico_ordine_aperto = (value) => {
  return {
    type: SET_STORICO,
    value,
  };
};
export const set_tavolo = (value) => {
  return {
    type: SET_TAVOLO,
    value,
  };
};
export const set_id_ordine = (value) => {
  return {
    type: SET_IDORDINE,
    value,
  };
};
export const set_tipo = (tipo) => {
  return {
    type: SET_TIPO,
    tipo,
  };
};
export const set_formula = (value) => {
  return {
    type: SET_IDFORMULA,
    value,
  };
};
export const togglenav = (value) => {
  return {
    type: SET_MENU,
    value,
  };
};
export const set_storico_uscite_ordine = (value) => {
  return {
    type: SET_STORICO_USCITE_ORDINE,
    value,
  };
};

export const set_ruolo_apertura_tavolo = (value) => {
  return {
    type: SET_RUOLO_APERTURA_TAVOLO,
    value,
  };
};export const set_carrello_tmp_online = (value) => {
  return {
    type: SET_CARRELLO_TMP_ONLINE,
    value,
  };
};

export const set_pin_tavolo = (value) => {
  return {
    type: SET_PIN_TAVOLO,
    value,
  };
};
