import React from "react";
import "../style/Table.css";

const TableStorico = ({ storico }) => {
  return (
    <table>
      <thead>
        <th>Quantità</th>
        <th>Nome</th>
        <th>Descrizione</th>
        <th>Prezzo Singolo</th>
      </thead>
      <tbody>
        {storico[0].carrellos.map((item, index) => {
          let color = index % 2 > 0 ? "white" : "aliceblue";
          return (
            <tr style={{ backgroundColor: color }} key={index}>
              <td>
                <label>Quantità</label>
                <span>{item.quantita}</span>
              </td>
              <td>
                <label>Nome</label>
                <span>
                  {item.prodotti.categorie.nome} {item.prodotti.nome}
                </span>
              </td>
              <td>
                <label>Descrizione</label>
                <span>{item.descrizione}</span>
              </td>
              <td>
                <label>Prezzo Singolo</label>
                <span>{item.prezzo.toFixed(2)}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableStorico;
