export class itemProdotto {
  constructor(
    card,
    special,
    id_prodotto_interno,
    note,
    quantita,
    prezzo,
    categoria,
    uscita

  ) {
    this.id_prodotto = card.id_prodotto;
    this.nome = card.nome;
    this.ingredienti_it = card.ingredienti_it;
    this.ingredienti_en = card.ingredienti_en;
    this.prezzo =
      (card.prezzo_offerte != 0.0 && card.prezzo_offerte !== undefined
        ? Number(card.prezzo_offerte)
        : Number(card.prezzo)) + Number(prezzo);
    this.quantita = quantita;
    this.id_prodotto_interno = id_prodotto_interno;
    this.note = note;
    this.special = special;
    this.categoria = categoria;
    this.uscita=uscita

  }
  /* mostraNomeCompleto() {
		return this.nome + " " + this.cognome;
	} */
}
