import React, { useState } from "react";
import "../style/BaseInfo.css";

const FormaggiInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
    let ing = props.ingredienti.find(
      (p) => p.id_ingredienti == event.target.value
    );
    var updatedList = [...props.formData.formaggi];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.formaggi,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
      ];
      props.setFormData({
        ...props.formData,
        formaggi: updatedList,
        totale: Number(props.formData.totale) + Number(ing.prezzo),
      });
    } else {
      updatedList.splice(
        props.formData.formaggi.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
      props.setFormData({
        ...props.formData,
        formaggi: updatedList,
        totale: Number(props.formData.totale) - Number(ing.prezzo),
      });
    }
  };

  return (
    <div className="container-fornoinfo">
      {checkList.map((item, index) => {
        if (item.categorie_ingredienti.nome == "Formaggi") {
          return (
            <label className="ingr" key={index}>
              <input
                value={item.id_ingredienti}
                type="checkbox"
                onChange={handleCheck}
                checked={
                  !props.formData.formaggi.find(
                    (p) => p.id_ingredienti == item.id_ingredienti
                  )
                    ? false
                    : true
                }
              />
              {item.nome} ({item.prezzo} €)
            </label>
          );
        }
      })}
    </div>
  );
};
export default FormaggiInfo;
