import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { node } from "../Axios";
import {
  connectMqtt,
  FETCH_TOPICS_SUCCESS,
  get_categorie,
  loading_login,
  login,
  login_fail,
  login_success,
  SET_MQTT_OPTIONS,
} from "../store/actions/login";
import {
  set_formula,
  set_id_ordine,
  set_storico_uscite_ordine,
} from "../store/actions/ordini";
import mqtt from "mqtt-browser";
import mqttTopics from "../utils/topics";

import "../style/Home.css";

const Home = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);
  const isLogin = useSelector((state) => state.login.isLogin);

  const token = useSelector((state) => state.login.token);

  const topics = useSelector((state) => state.login.topics);
  const mqttOptions = useSelector((state) => state.login.mqttOptions);
  const client = useSelector((state) => state.login.client);

  let { key,tipo } = useParams();
  console.log(key,tipo);
  useEffect(() => {
    const fetchLogin = async () => {
      await dispatch(login(key,tipo));
    };

    if (!isLogin  && key) {
      fetchLogin();
      console.log("recupero i parametri");
    }
   
  }, [dispatch, isLogin, key]);

 

  useEffect(() => {
    const fetchConnectMQTT = async () => {
      try {
        await dispatch(connectMqtt());
      } catch (error) {
        console.log("Errore durante il recupero dei dati:", error);
      }
    };

    if (isLogin && mqttOptions && topics ) {
      fetchConnectMQTT();
      console.log("provo a connettere mqtt");
    }
  }, [isLogin, topics, mqttOptions, dispatch]);


  return (
    <div className="container-home">
      
      {isLogin  && client ? <Navigate to="/home" /> : error ? <Navigate to="notfound"/> :null}
    </div>
  );
};

export default Home;
