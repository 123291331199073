import React, { useState } from "react";
import "../style/Modal.css";
import "../style/ModalUscite.css";

const Modal = (props) => {
  return (
    <div className="modal-container">
      <div className="container-infomodal">
        <div className="menu-close" onClick={() => props.setModalPin()}>
          <span className="material-symbols-rounded">close</span>
        </div>
        <div className="pin_verifica_container">
          <div className="spazio_input">
            <input
              id="first-name"
              className="campo_input"
              type="number"
              name="pin"
              placeholder="Inserisci Pin"
              value={props.pinLocal}
              onChange={(e) => props.setPinLocal(e.target.value)}
              onFocus={props.handleOnFocusModalPin}
            />
            <span className="campo_label" aria-hidden="true">
              <span className="field__label">Pin</span>
            </span>
            {props.error && <p>{props.errortext}</p>}
          </div>
          <a
            href="#fakeLink"
            onClick={() => props.handlePin()}
            className="btn purple"
            type="submit"
          >
            Verifica
          </a>
        </div>
      </div>
    </div>
  );
};
export default Modal;
