import { isContentEditable } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import "../style/BaseInfo.css";
import "../style/Form.css";
import RadioButton from "./RadioButton";

const BaseInfo = (props) => {
  let theme = props.formData.base;

  const onChangeTheme = (e) => {
    const { id } = e.target;

    const pos = props.basePizza.findIndex((p) => p.id_ingredienti == id);

    theme.forEach((element, index) => {
      if (pos === index) {
        theme[index] = true;
      } else {
        theme[index] = false;
      }
    });

    props.setFormData({
      ...props.formData,
      totale: props.id_formula == 1 ? props.basePizza[pos].prezzo : 0,
      base: theme,
      descrizione_base: e.target.value,
    });
  };

  return (
    <div className="baseinfo-container">
      <div className="spazio_input">
        <input
          id="first-name"
          type="text"
          className="campo_input"
          placeholder="Scrivi il tuo nome"
          onChange={(e) =>
            props.setFormData({ ...props.formData, nome: e.target.value })
          }
          value={props.formData.nome}
        />
        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Nome</span>
        </span>
      </div>
      {props.basePizza.map((item, index) => {
        return (
          <RadioButton
            key={index}
            id={item.id_ingredienti}
            value={item.nome}
            prezzo={props.id_formula == 1 ? item.prezzo : 0}
            text={item.nome}
            onChange={onChangeTheme}
            checked={theme[index]}
          />
        );
      })}
    </div>
  );
};

export default BaseInfo;
