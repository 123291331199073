let CARRELLO = [];

export const inizializzastorage = async () => {
  await localStorage.setItem("CARRELLO", JSON.stringify(CARRELLO));
};

export const removeSession = async () => {
  await localStorage.removeItem("CATEGORIE");
};
export const setCarrello = async (value) => {
  CARRELLO = value;
  await localStorage.setItem("CARRELLO", JSON.stringify(CARRELLO));
};

export const removecarrello = async () => {
  await localStorage.removeItem("CARRELLO");
};



export const removecategorie = async () => {
  await localStorage.removeItem("CATEGORIE");
};
