import { Navigate, Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import "../style/PersistLogin2.css";
import { useDispatch, useSelector } from "react-redux";
import { send_ordine, send_ordine_produzione } from "../store/actions/ordini";
import Bottombar from "./Bottombar";
import ModalPin from "../pages/ModalPin";
import ModalPinQRTavolo from "../pages/ModalPinQRTavolo";
import { useState } from "react";
import { set_pin, set_pin_fail, verifica_pin } from "../store/actions/login";

const PersistLogin2 = (props) => {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const totale = useSelector((state) => state.ordini.tot_prezzo);
  const storico = useSelector((state) => state.ordini.storico);
  const token = useSelector((state) => state.login.token);
  const pin = useSelector((state) => state.login.pin);
  const tipo = useSelector((state) => state.ordini.tipo);
  const error = useSelector((state) => state.login.error);
  const errortext = useSelector((state) => state.login.errortext);
  const ruoloaperturatavolo = useSelector(
    (state) => state.ordini.ruoloaperturatavolo
  );

  const [modal, setModal] = useState(false);
  const [pinLocal, setPinLocal] = useState("");

  const iscarrello = useSelector((state) => state.ordini.iscarrello);
  const handleSend = async () => {
    await dispatch(send_ordine(carrello, totale, token, tipo));
  };
  const handleSendQrTavolo = async () => {
    await dispatch(send_ordine_produzione(carrello, totale, token, tipo));
  };

  const handleSendControll = async () => {
    if (tipo == 4) {
      setModal(true);
    } else {
      await handleSend();
    }
  };const handleSendControllQRTavolo = async () => {
    if (ruoloaperturatavolo == 3 || ruoloaperturatavolo == 1) {
      setModal(true);
    } else {
      await handleSendQrTavolo();
    }
  };

  const togleModalPin = async () => {
    setModal(!modal);
  };
  const handleOnFocusModalPin = async () => {
    //  setPinLocal("");
    await dispatch(set_pin_fail(""));
  };
  const handlePin = async () => {
    let flag = await dispatch(verifica_pin(token, pinLocal));
    console.log(flag);

    if (flag) {
      setModal(false);
      //setPinLocal("");
      await handleSend();
    } else {
      setPinLocal("");
    }
    /*  await handleSend();
  await dispatch(set_pin(""))

    setModal(false); */
  };
  const handlePinQrTavolo = async () => {
    console.log(pinLocal);

    let flag = await dispatch(verifica_pin(token, pinLocal));
    console.log(flag);

    if (flag) {
      setModal(false);
      //setPinLocal("");
      await handleSendQrTavolo();
    } else {
      setPinLocal("");
    }
    /*  await handleSend();
  await dispatch(set_pin(""))

    setModal(false); */
  };
  return (
    <div className="container-prodotti">
      <div className="top">
        <Topbar
          iscarrello={iscarrello}
          onSend={handleSendControll}
          onSendQrTavolo={handleSendControllQRTavolo}
          storico={storico}
        />
      </div>
      <Outlet />
      {tipo == 4 && modal ? (
        <ModalPin
          setModalPin={togleModalPin}
          handlePin={handlePin}
          handleOnFocusModalPin={handleOnFocusModalPin}
          pinLocal={pinLocal}
          setPinLocal={setPinLocal}
          error={error}
          errortext={errortext}
        />
      ) : null}
      {ruoloaperturatavolo != "" && modal ? (
        <ModalPinQRTavolo
          setModalPin={togleModalPin}
          handlePin={handlePinQrTavolo}
          handleOnFocusModalPin={handleOnFocusModalPin}
          pinLocal={pinLocal}
          setPinLocal={setPinLocal}
          error={error}
          errortext={errortext}
        />
      ) : null}
      <Bottombar />
    </div>
  );
};

export default PersistLogin2;
