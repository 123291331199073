import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import "../style/Layout.css";

import Navbar from "./Navbar";
import Topbar from "./Topbar";

const Layout = (props) => {
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const isLogin = useSelector((state) => state.login.isLogin);

  return (
    <div className="container-layout">
      <div className="subcontainer-layout">
        {isLogin ? (
          <>
            <Navbar /> 

            <div
              className="corpo"
            >
              <Outlet />
            </div>
          </>
        ) :  <Navigate to="/notfound" /> }
      </div>
    </div>
  );
};
export default Layout;
