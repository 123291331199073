import React, { useEffect, useState } from "react";
import Cards from "../components/Cards";
import Modal from "../pages/ModalProdotto";
import ModalNota from "../pages/ModalNota";
import ModalUscite from "../pages/ModalUscite";
import "../style/Products.css";
import { useDispatch, useSelector } from "react-redux";
import {
  elimina_prodotto_cart,
  aggiungi_prodotto_cart,
  decrementa_prodotto_cart,
  recupera_carrello,
  get_prodotti,
  set_iscarrello,
  get_ingredienti,
  get_ingredienti_per_pizza_personalizzata,
  get_ingredienti_per_piadina_personalizzata,
  get_ingredienti_per_hamburger_personalizzata,
  get_ingredienti_antipasto_personalizzata,
  get_ingredienti_success,
  get_offerte,
  get_nomi_pizza,
} from "../store/actions/ordini";
import { setCarrello } from "../utils/session";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Topbar from "../components/Topbar";
import Spinner from "../components/Spinner";
import Personalizzami from "../components/Personalizzami";
import CardMenu from "../components/CardMenu";
import uuid from "react-uuid";
import { itemProdotto } from "../utils/itemProdotto";

const Products = () => {
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const carrello = useSelector((state) => state.ordini.carrello);
  const isLogin = useSelector((state) => state.login.isLogin);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const nuovo = useSelector((state) => state.login.attesanuovo);
  const loading = useSelector((state) => state.ordini.loading);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  const storico_uscite = useSelector((state) => state.ordini.storico_uscite);
  const storico = useSelector((state) => state.ordini.sorico);
  const id_formula = useSelector((state) => state.ordini.id_formula);

  const token = useSelector((state) => state.login.token);

  const [modal, setModal] = useState(false);
  const [modalUscite, setModalUscite] = useState(false);
  const [modalNota, setModalNota] = useState(false);
  const [datiModal, seDatiModal] = useState({
    card: "",
    onqty: "",
    id_prodotto_interno: "",
    prezzo: "",
  });

  const toggleModalUscite = (card, note = "", formData = null) => {
    setModalUscite(!modalUscite);
    seDatiModal({
      card: card,
      storico,
      note: note,
      formData: formData,
    });
  };
  const toggleModal = (card) => {
    setModal(!modal);
    seDatiModal({
      card: card,
    });
  };
  const toggleModalNota = (card) => {
    setModalNota(!modalNota);
    seDatiModal({
      card: card,
      storico,
      id_formula,
      storico_uscite,
    });
  };

  const iscarrello = false;
  const navigate = useNavigate();
  let { id, flag } = useParams();

  useEffect(() => {
    async function fetch_get_prodotti() {
      if (id == 18) {
        await dispatch(get_offerte(token, id_ordine));
      } else {
        await dispatch(get_prodotti(id, token, id_ordine));
      }

      if (id == 8) {
        await dispatch(get_ingredienti_per_pizza_personalizzata(token));
      } else if (id == 14) {
        await dispatch(get_ingredienti_per_piadina_personalizzata(token));
      } else if (id == 2) {
        await dispatch(get_ingredienti_antipasto_personalizzata(token));
      } else {
        let res = { data: { ingredienti: [] } };
        await dispatch(get_ingredienti_success(res));
      }
    }
    fetch_get_prodotti();
  }, [id]);

  useEffect(() => {
    dispatch(set_iscarrello(false));
  }, []);

  const modalpass = () => {
    return (
      <Modal
        toggleModal={toggleModal}
        datiModal={datiModal}
        onIncrement={handleIncrement}
      />
    );
  };
  const modalpassNota = () => {
    return (
      <ModalNota
        toggleModalNota={toggleModalNota}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        toggleModalUscite={toggleModalUscite}
        ingredienti={ingredienti}
        setModalNota={setModalNota}
        modalNota={modalNota}
      />
    );
  };
  const modalpassUscite = () => {
    return (
      <ModalUscite
        toggleModalUscite={toggleModalUscite}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        ingredienti={ingredienti}
        setModalUscite={setModalUscite}
        modalUscite={modalUscite}
      />
    );
  };
  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const preparapizza = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_pizza_personalizzata(token));
    navigate(`/pizzastepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const preparapiadina = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_piadina_personalizzata(token));
    navigate(`/piadinastepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const preparapanino = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_hamburger_personalizzata(token));
    navigate(`/hamburgerstepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const preparafiftyfifty = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_nomi_pizza(token));
    navigate(`/fiftyStepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const handleIncrement = (card, note, formData = null, uscita = 0) => {
    let special = note != "" ? 1 : 0;
    let quantita = 1;

    let id_prodotto_interno = uuid();
    let prezzo = formData == null ? 0 : formData.totale;
    let nota = note;
    let controllo = true;
    let categoria = card.categorieIdCategoria;

    formData != null &&
      formData.ing_scelti.forEach((item, index) => {
        if (controllo && formData.ing_scelti.length > 0) {
          nota = nota + " AGGIUNGI";
          controllo = false;
        }
        nota = nota + " - " + item.nome;
        special = 1;
      });

    const p = new itemProdotto(
      card,
      special,
      id_prodotto_interno,
      nota,
      quantita,
      prezzo,
      categoria,
      uscita
    );

    dispatch(aggiungi_prodotto_cart(p));
    setModalUscite(false);
    setModalNota(false);
  };

  let itemList = [];
  const map_card = () => {
    prodotti &&
    prodotti.forEach((prodotto, index) => {
      let qty = 0;

      carrello.forEach((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty += elemento.quantita;
        }
      });

      itemList.push(
        <Cards
          toggleModal={toggleModal}
          toggleModalNota={toggleModalNota}
          toggleModalUscite={toggleModalUscite}
          key={index}
          card={prodotto}
          id_formula={id_formula}
          onqty={qty}
          onIncrement={handleIncrement}
          storico_uscite={storico_uscite}
        />
      );
    });
    itemList.push();
    return itemList;
  };
  const map_card2 = () =>
    //pizza personalizzata
    prodotti &&
    prodotti.map((prodotto, index) => (
      <Personalizzami
        toggleModal={toggleModal}
        key={index}
        card={prodotto}
        prepara={preparapizza}
      />
    ));

  const map_card3 = () =>
    //hamburgher personalizzato

    prodotti &&
    prodotti.map((prodotto, index) => (
      <Personalizzami
        toggleModal={toggleModal}
        key={index}
        card={prodotto}
        prepara={preparapanino}
      />
    ));

  const map_card4 = () => {
    //menu completo

    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let descrizione = "";
      prodotto.composizione_menus.forEach((cm, index) => {
        descrizione = descrizione + cm.Prodotti_Correlati.categorie.nome + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.nome + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.ingredienti_it + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.ingredienti_en + " ";
      });
      carrello.forEach((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty += elemento.quantita;
        }
      });
      prodotto.ingredienti_it = descrizione;
      itemList.push(
        <CardMenu
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          onqty={qty}
          onIncrement={handleIncrement}
          toggleModalNota={toggleModalNota}
        />
      );
    });
    itemList.push();
    return itemList;
  };

  const map_card5 = () =>
    //piadina personalizzata
    prodotti &&
    prodotti.map((prodotto, index) => (
      <Personalizzami
        toggleModal={toggleModal}
        key={index}
        card={prodotto}
        prepara={preparapiadina}
      />
    ));
  const map_card6 = () =>
    //pizza fifty fifty
    prodotti &&
    prodotti.map((prodotto, index) => (
      <Personalizzami
        toggleModal={toggleModal}
        key={index}
        card={prodotto}
        prepara={preparafiftyfifty}
      />
    ));
    const handleInputChange = (e) => {
      const text = e.target.value;
      setInputText(text);
      console.log(itemList);
      const filtered = itemList.filter((item) =>
        item.props.card.nome.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredItems(filtered);
    };
  return (
    <>
    <div className="search">
      {" "}
      {flag == 0 ? (
        <div className="spazio_input">
          <input
            id="first-name"
            type="text"
            className="campo_input"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Search"
          />
         
        </div>
      ) : null}
    </div>
    <div className="prodotti">
      {modal ? modalpass() : null}
      {modalNota ? modalpassNota() : null}
      {modalUscite ? modalpassUscite() : null}
      {loading ? (
        <Spinner />
      ) : flag == 0 ? (
        filteredItems.length > 0 ? (
          filteredItems
        ) : (
          map_card()
        )
      ) : flag == 1 ? (
        map_card2()
      ) : flag == 2 ? (
        map_card3()
      ) : flag == 3 ? (
        map_card4()
      ) : flag == 4 ? (
        map_card5()
      ) : flag == 6 ? (
        map_card6()
      ) : (
        "ciao"
      )}
    </div>
    </>
  );
};

export default Products;
