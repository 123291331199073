import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardCart from "../components/CardCart";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import TableCarrello from "../components/TableCarrello";
import Topbar from "../components/Topbar";
import {
  aggiungi_prodotto_cart,
  decrementa_prodotto,
  elimina_prodotto_cart,
  incrementa_prodotto,
  send_ordine,
  set_iscarrello,
} from "../store/actions/ordini";
import "../style/Carrello.css";
import { setCarrello } from "../utils/session";
import Modal from "./ModalProdotto";

const Carrello = () => {
  const dispatch = useDispatch();
  //TODO togliere logica ai componenti
  const nuovo = useSelector((state) => state.login.attesanuovo);
  const isLogin = useSelector((state) => state.login.isLogin);
  const totale = useSelector((state) => state.ordini.tot_prezzo);
  const loading = useSelector((state) => state.ordini.loading);

  const iscarrello = true;

  const carrello = useSelector((state) => state.ordini.carrello);
  console.log(carrello)
  const [modal, setModal] = useState(false);
  const [datiModal, seDatiModal] = useState({
    card: "",
    onqty: "",
    id_prodotto_interno: "",
    prezzo: "",
  });
  const toggleModal = (card, onqty, id_prodotto_interno, prezzo) => {
    setModal(!modal);
    seDatiModal({
      card: card,
      onqty: onqty,
      id_prodotto_interno: id_prodotto_interno,
      prezzo: prezzo,
    });
  };
  useEffect(() => {
    dispatch(set_iscarrello(true));
  }, []);
  useEffect(() => {
    dispatch(set_iscarrello(true));
    setCarrello(carrello);
  }, [carrello]);
  const handleIncrement = ( id_prodotto_interno) => {
    dispatch(incrementa_prodotto(id_prodotto_interno));
  };

  const handleDecrement = (id_prodotto_interno) => {
    dispatch(decrementa_prodotto(id_prodotto_interno));
    
   /* 
    if (qty == 1) {
     // dispatch(elimina_prodotto_cart(p));
    } else if (qty > 1) {
      p.quantita = qty - 1; */
     // dispatch(decrementa_prodotto_cart(p));
    //}
   
  };
  const modalpass = (prodotto, qty, id_prodotto_interno, prezzo) => {
    return (
      <Modal
        toggleModal={toggleModal}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
      />
    );
  };
  return (
    <div className="prodotti margin50">
      {modal ? modalpass() : null}
      {loading ? (
        <Spinner />
      ) : <TableCarrello carrello={carrello} onIncrement={handleIncrement} onDecrement={handleDecrement}/>/* (
        carrello.map((prodotto, index) => (
          <CardCart
            key={index}
            card={prodotto}
            id_prodotto_interno={prodotto.id_prodotto_interno}
            onqty={prodotto.quantita}
            onIncrement={handleIncrement}
            onDecrement={handleDecrement}
            toggleModal={toggleModal}
          />
        ))
      ) */}
     
    </div>
  );
};

export default Carrello;
