import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import "../style/Home.css";

import Navbar from "./Navbar";
import Topbar from "./Topbar";

const HomeContenuto = (props) => {
  const pin_tavolo = useSelector((state) => state.ordini.pin_tavolo);
  const tipo = useSelector((state) => state.ordini.tipo);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);

  return (
    <div className="container-home">
      {(id_ruolo == 3 || id_ruolo == 2) && tipo==2 ? (
        <div className="margin50">
          <h2>Come Utilizzare il sistema?</h2>
          <div className="info">
            <p>
              Fai un ordine. Invialo. quando tutti avete inviato l'ordino, uno
              di voi per confermare clicchi su "Go" e inserendo il pin mandi
              l'ordine in produzione. I successivi ordini non hanno bisogno
              della conferma poichè verranno automaticamente inviati per essere
              preparati
            </p>
          </div>
          <div className="pin-container">
            <div className="text">PIN: </div>
            <div className="pin">{pin_tavolo} </div>
          </div>
        </div>
      ) : id_ruolo == 4 && tipo==2 ? (
        <div className="margin50">
          <h2>Come Utilizzare il sistema?</h2>
          <div className="info">
            <p>
              Ordina ciò che vuoi e invialo al tablet. Per confermare gli ordini
              tuoi e degli altri commensali seduti con te invia l'ordine totale dal Tablet
            </p>
          </div>
        </div>
      ):<div className="margin50">
      <h2>Come Utilizzare il sistema?</h2>
      <div className="info">
        <p>
          Ordina ciò che vuoi e invialo l'ordine. Ti sara servito sotto l'ombrellone
        </p>
      </div>
    </div>}
    </div>
  );
};
export default HomeContenuto;
