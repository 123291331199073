import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import "../style/Topbar.css";

const Topbar = (props) => {
  const qty_prodotti = useSelector((state) => state.ordini.qty_prodotti);
  const tot_prezzo = useSelector((state) => state.ordini.tot_prezzo);
  const coperti = useSelector((state) => state.ordini.coperti);
  const tot_generale = useSelector((state) => state.ordini.tot_generale);
  const tavolo = useSelector((state) => state.ordini.tavolo);
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const qty_bibite = useSelector((state) => state.ordini.qty_bibite);
  const id_formula = useSelector((state) => state.ordini.id_formula);
  const storico = useSelector((state) => state.ordini.storico);
  const ruoloaperturatavolo = useSelector(
    (state) => state.ordini.ruoloaperturatavolo
  );const car_tmp_online = useSelector(
    (state) => state.ordini.car_tmp_online
  );

/*   console.log("is carrello", props.iscarrello);
  console.log("formula", id_formula);
  console.log("quantità propdottiu", qty_prodotti); */
  return (
    <div className="container_topbar">
  
      {qty_prodotti > 0 ? (
        <NavLink to="/carrello" className="menu-topbar">
          <div className="menu_topbar">
            <span className="material-symbols-rounded">shopping_cart</span>
            <label>{qty_prodotti}</label>
          </div>
        </NavLink>
      ) : null}
      {id_formula === 2 ? (
        qty_prodotti > 0 &&
        props.iscarrello &&
        (qty_bibite >= 1 || storico == 1) ? (
          <a
            href="#fakeLink"
            onClick={() => props.onSend()}
            className="menu_topbar"
          >
            <label>invia</label>
            <span className="material-symbols-rounded send-btn">send</span>
          </a>
        ) : null
      ) : qty_prodotti > 0 && props.iscarrello ? (
        <a
          href="#fakeLink"
          onClick={() => props.onSend()}
          className="menu_topbar"
        >
          <label>invia</label>
          <span className="material-symbols-rounded send-btn">send</span>
        </a>
      ) : null}

      {ruoloaperturatavolo != "" && car_tmp_online==1 && storico ==0 ? (
        <a
          href="#fakeLink"
          onClick={() => props.onSendQrTavolo()}
          className="menu_topbar"
        >
          <label>Go</label>
          <span className="material-symbols-rounded send-btn">send</span>
        </a>
      ) : null}
      {/*  {props.iscarrello && qty_prodotti > 0 && qty_bibite >= coperti ? (
              <a
                href="#fakeLink"
                onClick={() => props.onSend()}
                className="menu-topbar"
              >
                Invia
              </a>
            ) : null} */}
    </div>
  );
};
export default Topbar;
