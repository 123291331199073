import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./style/App.css";

import Products from "./pages/Products";

import Layout from "./components/Layout";
import NotFound from "./components/NotFound";
import Home from "./pages/Home";

import PersistLogin2 from "./components/PersistLogin2";

import Carrello from "./pages/Carrello";
import Spinner from "./components/Spinner";
import PizzaStepper from "./pages/PizzaStepper";
import HamburgerStepper from "./pages/HamburgerStepper";
import PiadinaStepper from "./pages/PiadinaStepper";
import FiftyStepper from "./pages/FiftyStepper";
import Storico from "./pages/Storico";
import HomeContenuto from "./components/HomeContenuto";


//TODO creare store per gestire tutti gli errori
//TODO implementare funzione che restituisce prezzo totale del carrello poichè il logout farebbe perbere il totale
function App() {


  const loading = useSelector((state) => state.login.loading);
  return (
    <div className="App">
      {loading ? (
        <Spinner />
      ) : (
        <Router>
          <Routes>
            <Route element={<Home />}  path="/:key/:tipo" exact></Route>
            <Route path="/" element={<Layout />}>
            
              <Route element={<PersistLogin2 />}>
              <Route
                  element={<HomeContenuto />}
                  path="/home"
                  exact
                />
                <Route
                  element={<PizzaStepper />}
                  path="/pizzastepper/:id/:uui"
                  exact
                />
                <Route
                  element={<PiadinaStepper />}
                  path="/piadinastepper/:id/:uui"
                  exact
                />
                <Route
                  element={<HamburgerStepper />}
                  path="/hamburgerstepper/:id/:uui"
                  exact
                />
                  <Route
                    element={<FiftyStepper />}
                    path="/fiftyStepper/:id/:uui"
                    exact
                  />
                <Route element={<Products />} path="/products/:id/:flag" />
                <Route element={<Carrello />} path="/carrello" />
                <Route element={<Storico />} path="/storico" />

                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>

            <Route path="/notfound" element={<NotFound />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
